<template>
    <vx-card title="Visit History">
      <vs-table stripe :data="parkingData" v-if="parkingData.length">
          <template #thead>
              <vs-th>Modal</vs-th>
              <vs-th>Registration Plate</vs-th>
              <vs-th>Entry Date</vs-th>
          </template>
          <template :slot-scope="{data}">
              <vs-tr v-for="(tr, index) in parkingData" :key="index">
                  <vs-td>{{tr.carModel}}</vs-td>
                  <vs-td>{{tr.registrationPlate}}</vs-td>
                  <vs-td>{{tr.entryDateTime | formatLongDateTime}}</vs-td>
              </vs-tr>
          </template>
      </vs-table>
      <NoData v-else class="mt-base" :headTitle="noData.title" :description="noData.description" ></NoData>
  </vx-card>
</template>

<script>
import { visitHistoryData } from "../../store/api/visitHistory";

export default {
    data() {
        return {
          parkingData: [],
          noData: {
              title: "No Records Found",
            }
        }
    },
    async created() {
      await this.loadVisitHistory();
    },
    methods:{
      async loadVisitHistory(){
        this.parkingData = await visitHistoryData.SearchUserHistory();
      },
      searchByPage() {
      },
    }
}
</script>

