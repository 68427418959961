import { axiosInstance } from "../../axios";
const GETSearchUserHistory = `/api/VisitHistory/SearchUserHistory`;
const GETSearchUserHistoryByDate = `/api/VisitHistory/SearchHistoryByDate/`;

const SearchUserHistory = async function() {
    try {
      const response = await axiosInstance.bhAPIInstance.get(
        GETSearchUserHistory
      );
      let data = parseList(response);
      return data;
    } catch (error) {
      return [];
    }
  };
  
  const SearchUserHistoryByDate = async function(date) {
    try {
      const response = await axiosInstance.bhAPIInstance.get(
        GETSearchUserHistoryByDate + date
      );
      let data = parseList(response);
      return data;
    } catch (error) {
      return [];
    }
  };

  const parseList = response => {
    if (response.status === 204) throw Error("Please Select Company");
    if (response.status !== 200) throw Error(response.message);
    if (!response.data) return [];
    let list = response.data;
    if (typeof list !== "object") {
      list = [];
    }
    return list;
  };

  export const visitHistoryData = {
    SearchUserHistory,
    SearchUserHistoryByDate
  };
  